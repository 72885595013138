<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import General from "@/services/General";
    import ModalEditTermsAndConditions from  "@/components/modals/monitor/modalEditTermsAndConditions";
    import ModalAddNewTermsAndConditions from  "@/components/modals/monitor/modalAddNewTermsAndConditions";
    import ModalViewTermsAndConditions from  "@/components/modals/monitor/modalViewTermsAndConditions";


    export default {
        components: { Layout, PageHeader , ModalEditTermsAndConditions , ModalAddNewTermsAndConditions, ModalViewTermsAndConditions },
        page: {
            title: "Terms and Conditions",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                showLoader:false,
                error: null,
                filterData: false,
                filterDataAll: false,
                tableData: [],
                url: '',
                title: "Terms and Conditions",
                items: [
                    {
                        text: "Terms and Conditions",
                        active: true,
                        href: "/",
                    },
                ],
                filterInput: {
                },
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "version",
                sortDesc: true,
                isTableLoading: false,
                termsAndConditionsData : {} ,
                fields: [
                    {
                        key: "tenant",
                        label: "Tenant",
                        visible:false,

                    },
                    {
                        key: "title",
                        label: "Title",
                        visible:true,
                    },
                    {
                        key: "status",
                        label: "Status",
                        visible:true,
                    },
                    {
                        key: "url",
                        label: "Url",
                        visible:true,
                    },
                    {
                        key: "version",
                        label: "Version",
                        visible:true,
                    },

                    "action",
                ],

                totalRowsAll: 0,

            };
        },

        async mounted() {
            try {
                await this.getTermsAndConditions();
            } catch (error) {
                console.error('Error during component initialization:', error);
            }
        },
        methods: {
            actionEditLabel(status){
                return status ? 'Copy to new draft' : 'Edit'
            },
            async getTermsAndConditions() {
                this.showLoader = true
                try {
                    this.filterDataAll = true;
                    this.isTableLoading = true;
                    const response = await General.getTermsAndConditions();
                    this.tableData = response.data.data;
                    this.totalRowsAll = this.tableData.length
                    this.tableData.forEach(item => {
                        if (item.status === 1) {
                            this.url = item.url;
                        }
                    });

                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                    this.totalRowsAll = 0
                }
                finally {
                    this.showLoader = false
                    this.isTableLoading = false;
                }
            },

            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRowsAll = filteredItems.length;
                this.currentPage = 1;
            },

            callModalEditTermsAndConditions(data) {
                this.$bvModal.show("edit_terms_and_conditions");
                this.termsAndConditionsData = {...this.termsAndConditionsData, ...data}
            },

            callModalAddNewTermsAndConditions() {
                this.$bvModal.show("add_new_terms_and_conditions");
            },

            callModalViewTermsAndConditions(data) {
                this.$bvModal.show("view_terms_and_conditions");
                this.termsAndConditionsData = data
            },

            publishedStatus(status){
                switch (status) {
                    case 0:
                        return 'Draft';
                    case 1:
                        return 'Published';
                    case 2:
                        return 'Archived';
                    default:
                        return ''
                }
            },
            copyUrl() {
                try {
                    navigator.clipboard.writeText(this.url);

                    this.successmsg('Url copied to clipboard!');
                } catch (error) {
                    this.failedmsg('Failed to copy url');
                }
            },
        },

        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row" v-if="!showLoader">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <a  href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="callModalAddNewTermsAndConditions">
                                        <i class="mdi mdi-plus me-2"></i>
                                        Add New
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>

                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :busy="isTableLoading"
                                    :items="tableData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item" v-if="data.item.status == 1 || data.item.status == 0">
                                            <a href="javascript:void(0);" class="px-2 text-primary" :title="actionEditLabel(data.item.status)" @click="callModalEditTermsAndConditions(data.item)">
                                                <i class="fa fa-copy font-size-18" v-if="data.item.status == 1"></i>
                                                <i class="uil uil-pen font-size-18" v-else-if="data.item.status == 0"></i>
                                            </a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-primary" title="View " @click="callModalViewTermsAndConditions(data.item)">
                                                <i class="uil uil-eye font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>

                                <template v-slot:cell(status)="data">
                                    <div>{{publishedStatus(data.item.status)}}</div>
                                </template>
                                <template v-slot:cell(url)="data">
                                    <div class="d-flex">
                                        <a v-if="data.item.status == 1" :href="data.item.url" class="px-2" title="Copy Url">{{ data.item.url }}</a>
                                        <i v-if="data.item.status == 1" class="fa fa-copy font-size-18 text-primary" @click="copyUrl(data.item.url)" title="Copy URL" style="cursor: pointer;"></i>
                                    </div>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>
                            </b-table>

                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                        class="dataTables_paginate paging_simple_numbers float-end"
                                >
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="card-body d-flex align-items-center justify-content-center" >
                <b-spinner large></b-spinner>
            </div>
        </div>

        <!--MODALS-->
        <ModalEditTermsAndConditions :termsAndConditionsData="termsAndConditionsData" @onRefresh="getTermsAndConditions()"></ModalEditTermsAndConditions>
        <ModalViewTermsAndConditions :termsAndConditionsData="termsAndConditionsData"></ModalViewTermsAndConditions>
        <ModalAddNewTermsAndConditions @onRefresh="getTermsAndConditions()"></ModalAddNewTermsAndConditions>
        <!--MODALS-->

    </Layout>
</template>

